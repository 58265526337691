.bottomNavContainer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #fff;
  border-top: 0.5px solid #ccc;
  height: 70px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100000
}

.navItem {
  flex: 1;
  height: 100%;
  text-align: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

@media (min-width: 1024px) {
  .bottomNavContainer {
    position: fixed;
    top: 0;
    left: 0;
    right: unset;
    bottom: 0;
    width: 70px;
    height: 100vh;
    flex-direction: column;
    border-top: none;
    border-right: 0.5px solid #ccc;
    justify-content: flex-start;
  }

  .navItem {
    padding: 20px 0;
    flex: none;
    margin-bottom: 20ox;
    width: 49px;
    height: 30px;
    border-radius: 10px;
    aspect-ratio: 1;
  }
}
