.container {
  background-color: #fff;
  width: 90%;
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;
  box-sizing: border-box;
}

.hidden {
  display: none;
}

.title {
  font-size: 18px;
  font-weight: bold;
  margin: 0;
  margin-bottom: 5px;
  width: 100%;
}

.subtitle {
  font-size: 14px;
  margin: 0;
  margin-bottom: 10px;
  width: 100%;
}

.sep {
  height: 0.5px;
  background-color: #ccc;
  margin: 5px 0;
}

.step {
  display: flex;
  align-items: center;
  margin: 0;
  width: 100%;
}
.step p {
  margin: 0;
  padding: 10px;
}

.btn {
  background-color: black;
  color: white;
  font-weight: 500;
  font-size: 14px;
  padding: 10px 0;
  border: none;
  border-radius: 5px;
  margin-top: 15px;
  cursor: pointer;
  text-align: center;
  width: 100%;
}
