@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
.pulse {
  animation: pulse 1.5s infinite ease-in-out;
  background-color: #eee;
  animation: pulse 1.5s infinite ease-in-out;
  height: 12px;
}

.card {
  display: flex;
  justify-content: space-between;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  width: 90%;
  /* max-width: 700px; */
  background-color: white;
  flex-direction: row;
  margin-bottom: 20px;
  cursor: pointer;
  transition: transform 0.2s;
  max-height: 232px;
  position: relative;
  min-height: 180px;
}

.textContainer {
  width: 50%;
}

.date {
  color: #716d6d;
  margin: 0;
  margin-top: 10px;
  margin-left: 10px;
  font-size: 14px;
}

.title {
  font-size: 15px;
  font-weight: 500;
  margin-top: 2px;
  margin-left: 10px;
  margin-bottom: 0;
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  max-height: 2.5em;
  max-lines: 2;
}

.content {
  color: #716d6d;
  font-size: 14px;
  margin: 10px 10px;
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  max-height: 2.5em;
  max-lines: 2;
}

.categoriesContainer {
  display: flex;
  flex-direction: row;
  margin-left: 10px;
  overflow: hidden;
}

.category {
  background-color: #e6e6e6;
  color: #333;
  padding: 5px;
  border-radius: 8px;
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
}


.likeContainer {
  display: flex;
  margin-bottom: 10px;
  margin-top: 5px;
  margin-left: 10px;
}

.image {
  width: 50%;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  object-fit: cover;
  background-color: #eee;
}

.status {
  position: absolute;
  top: 10px;
  right: 10px;
  color: white;
  font-weight: 500;
  padding: 5px;
  border-radius: 8px;
  font-size: 14px;
}

@media (min-width: 420px) {
  .content {
    max-height: 3.75em;
    max-lines: 3;
  }
}
@media (min-width: 460px) {
  .content {
    max-height: 4.9em;
    max-lines: 4;
  }
}
@media (min-width: 1024px) {
  .card:hover {
    transform: translateY(-5px);
  }
  .title {
    font-size: 18px;
  }
}
