.container {
  background-color: white;
  min-height: calc(100vh - 20px);
  width: 100%;
  /* max-width: 500px; */
  /* max-height: 500px; */
  position: absolute;
  top: 20px;
  border-radius: 40px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  box-shadow: 0 -10px 20px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
  width: 100%;
  max-width: 700px;
  min-height: 85vh;
}

.columns {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
  width: 100%;
  min-height: 85vh;
}

.back {
  display: flex;
  padding-top: 30px;
  width: 90%;
  cursor: pointer;
}
.back:active {
  opacity: 0.7;
}
.back img {
  margin-right: 7px;
  pointer-events: none;
}

.back p {
  color: var(--blue);
  opacity: 0.7;
  font-weight: bold;
  margin: 0;
}

.footer {
  width: 100%;
  margin-top: 40px;
}

@media (min-width: 990px) {
  .container {
    top: 30px;
  }
  .columns {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin: 0;
    width: 75vw;
  }
  .footer {
    position: absolute;
    bottom: 0;
  }
}
