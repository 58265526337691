.card {
  background-color: white;
  padding: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 14px;
  margin: 5px 0;
  box-shadow: 0px 2px 4px rgba(170, 170, 170, 0.1);
}
.card:active {
  opacity: 0.7;
}
.edit:active {
  opacity: 1;
}

.row {
  display: flex;
  align-items: center;
  max-width: 85%;
}

.iconContainer {
  margin-right: 10px;
  background-color: rgba(52, 122, 143, 0.38);
  padding: 7px;
  border-radius: 50%;
  min-width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.title {
  font-size: 18px;
  /* max-width: 70%; */
}

.icon {
  margin-left: 5px;
  cursor: pointer;
}
