.container {
  display: flex;
  justify-content: center;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  max-width: 700px;
}

.main {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.price {
  font-size: 30px;
  font-weight: 600;
}

.mainDetails {
  color: #666;
}

.listIconContainer {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eee;
}

.card {
  background-color: white;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 10px;
  margin-top: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.cardRow {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
}

.value {
  font-weight: 500;
  max-width: 45%;
  text-align: right;
  line-break: anywhere;
}

.button {
  margin-top: 20px;
  background-color: #000;
  width: 100%;
  height: 50px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bold;
  font-size: 15px;
  color: white;
  font-size: 15px;
  font-weight: bold;
  border: none;
}
