.overlay {
    position: fixed;
    inset: 0;
    background-color: rgba(31, 41, 55, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 50;
    padding-bottom: 70px;
}
@media screen and (min-width: 1024px) {
    .overlay {
        padding-left: 70px;
    }
}

.popup {
    background-color: white;
    padding: 20px 0;
    border-radius: 15px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    width: 90%;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transform: scale(1);
    transition: transform 0.1s ease;
}

.scaleIn {
    transform: scale(1);
}

.scaleOut {
    transform: scale(0.8);
}

.fadeIn {
    opacity: 1;
    transition: opacity 0.1s ease;
}

.fadeOut {
    opacity: 0;
    transition: opacity 0.1s ease;
}

.header {
    font-size: 1.125rem;
    font-weight: 600;
    color: #000;
    margin: 0;
    width: 90%;
}

.sep {
    height: 1px;
    width: 100%;
    background-color: #ccc;
    margin: 10px 0;
}

.buttonContainer {
    display: flex;
    justify-content: flex-end;
    gap: 16px;
    width: 90%;
    margin-top: 10px;
}

.button {
    border-radius: 6px;
    font-weight: 600;
    font-size: 15px;
    cursor: pointer;
    transition: background-color 0.2s ease, opacity 0.2s ease;
    font-weight: 600;
    width: 100px;
    height: 40px;
}

.cancelButton {
    background-color: white;
    border: 1.5px solid black;
    color: black
}

.saveButton {
    background-color: black;
    color: white;
    border: none;
}

button:active {
    opacity: 0.7;
}
