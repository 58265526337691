.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.pageContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 700px;
  padding-bottom: 20px;
}

.image {
  border-radius: 20px;
  width: 100%;
  aspect-ratio: 16/9;
  object-fit: cover;
  background-color: #eee;
}

.contentContainer {
  width: 90%;
}

.title {
  font-size: 22px;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 30px;
  color: rgb(36, 118, 149);
}

.content {
  font-size: 16px;
  margin: 0;
  font-weight: 500;
}

.link {
  color: rgb(36, 118, 149);
  cursor: pointer;
  text-decoration: none;
}
.link:active {
  opacity: 0.7;
}

.button {
  background-color: rgb(63, 170, 210);
  color: white;
  outline: none;
  border: none;
  border-radius: 5px;
  padding: 15px 20px;
  font-size: 16px;
  font-weight: 500;
  margin: -20px 0
}
