.listItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 5px;
    width: 90%;
    cursor: pointer;
  }
  
  .listItemContent {
    display: flex;
    align-items: center;
  }
  
  .listItemTitle {
    margin-left: 15px;
    font-size: 16px;
  }
  
  .sep {
    background-color: black;
    width: 90%;
    height: 1px;
    opacity: 0.1;
  }
  