.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f6f7f7;
  flex: 1
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  max-width: 700px;
  flex: 1
}

.title {
  margin-top: 20px;
  margin-bottom: 10px;
  text-align: center;
  width: 90%;
}

.subtitle {
  font-size: 18px;
  margin: 0;
  margin-bottom: 10px;
  width: 90%;
  text-align: center;
}