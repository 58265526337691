.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.pageContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 700px;
}

.image {
  border-radius: 20px;
  width: 100%;
  aspect-ratio: 16/9;
  object-fit: cover;
  background-color: #eee;
}

.categoriesContainer {
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  flex-wrap: wrap;
  row-gap: 8px;
  box-sizing: border-box;
  padding: 0 5%;

  scrollbar-width: none;
  -ms-overflow-style: none;
}

.categoriesContainer::-webkit-scrollbar {
  display: none;
}

.category {
  background-color: #e6e6e6;
  color: #333;
  padding: 5px 15px;
  border-radius: 8px;
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
  text-wrap: nowrap;
}

.contentContainer {
  width: 90%;
}

.title {
  font-size: 18px;
  font-weight: 500;
  margin-top: 2px;
  margin-bottom: 0;
}

.date {
  color: #716d6d;
  margin-top: 2px;
  margin-bottom: 10px;
  font-size: 14px;
}

.content {
  font-size: 16px;
  margin: 0;
}

.likeBtn {
  border: none;
  color: black;
  padding: 10px 20px;
  border-radius: 8px;
  margin-top: 30px;
  margin-bottom: 10px;
  background: none;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 18px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
}

.likeBtn:hover {
  opacity: 0.7;
}

.postBtn {
  position: fixed;
  background-color: #3E6471;
  border-radius: 50%;
  border: none;
  outline: none;
  right: 15px;
  bottom: 85px;
  justify-content: "center";
  align-items: "center";
  height: 50px;
  width: 50px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}
.postBtn:active {
  opacity: 0.7;
}
@media (min-width: 1024px) {
  .postBtn {
    right: calc(50% - 500px);
  }
}

@media (min-width: 700px) {
  .categoriesContainer,
  .contentContainer {
    width: 100%;
  }
  .categoriesContainer {
    width: 100%;
    padding: 0;
    box-sizing: unset;
  }
  .title {
    font-size: 24px;
  }
  .date {
    font-size: unset;
  }
}
