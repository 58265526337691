.card {
  width: 90%;
  border-radius: 10px;
  padding: 20px;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to right, #5e96ab, #3e6471, #253c44);
  color: white;
  text-align: center;
  box-sizing: border-box;
}

.cardTitle {
  font-size: 20px;
  font-weight: 600;
  margin: 0;
}

.expirationDate {
  color: white;
  margin: 0;
  margin-top: 2px;
}

.btn {
  background-color: white;
  border-radius: 25px;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  margin-top: 25px;
  border: none;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  color: black;
}
.btn:active {
  opacity: 0.7;
}
