.container {
  height: 80px;
  padding: 0 20px;
  box-sizing: border-box;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  width: 100%;
  position: relative;
}

.pageName {
  justify-content: center;
  padding: 0;
  height: 100%;
}
.pageName h3 {
  margin: 0;
  font-size: 20px;
  font-weight: 600;
}
.backBtn {
  position: absolute;
  left: 0;
  cursor: pointer;
}
.backBtn:active {
  opacity: 0.7;
}

.logo {
  width: 100px;
  max-width: 100px;
  margin: 15px 0;
  cursor: pointer;
}
.whatsapp {
  cursor: pointer;
}
.whatsapp:active {
  opacity: 0.7;
}

@media (min-width: 1024px) {
  .noWidth {
    padding: 0 -70px;
    width: calc(100vw - 140px);
  }
  .width {
    padding: 0 70px;
    width: calc(100vw - 70px);
  }
}