.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  margin: 10px;
  height: 55px;
  border-radius: 10px;
  border-style: solid;
  border-color: black;
  border-width: 1px;
}

.container input {
  border: none;
  outline: none;
  height: 90%;
  width: 90%;
  background: none;
  font-size: 16px;
  font-weight: 500;
}

.error {
  border-color: #BC0000;
}
