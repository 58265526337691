body {
  margin: 0;
  padding: 0;
  width: 100vw;
  overflow-x: hidden;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F6F7F7;
  padding-bottom: 70px;
  box-sizing: border-box;
}

@media (min-width: 1024px) {
  body {
    padding-bottom: 0;
    padding-left: 70px;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button {
  -webkit-appearance: none;
  cursor: pointer;
}

form {
  width: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}