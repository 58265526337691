.container {
  width: 100vw;
  display: flex;
  justify-content: center;
  margin-bottom: -50px;
}

@media (min-width: 1024px) {
  .content {
    margin-left: -70px;
    margin-bottom: 20px;
  }
}

.content {
  width: 100%;
  max-width: 700px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cube {
  margin-top: 40px;
  margin-bottom: 20px;
  width: 150px;
  height: 70px;
  object-fit: contain;
  aspect-ratio: 1;
  pointer-events: none;
}

.name {
  text-align: center;
  color: black;
  font-size: 26px;
  font-weight: bold;
  margin-bottom: 40px;
}

.pages {
  margin-bottom: 20px;
  width: 120px;
  object-fit: contain;
}

.inputContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 90%;
  margin-bottom: 20px;
}

.inputContainer label {
  width: 100%;
  font-size: 14px;
  margin-bottom: 10px;
}

.section {
  width: 90%;
  color: var(--blue);
  font-weight: bold;
  font-size: 16px;
}
.inputContainer select,
.inputContainer input {
  width: 100%;
  background-color: var(--background);
  height: 45px;
  border-style: solid;
  border-color: black;
  color: black;
  border-width: 1px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 10px;
  font: unset;
  font-size: 16px;
  box-sizing: border-box;
}
.searchSelect {
  width: 100%;
  border-style: solid;
  border-color: black;
  color: black;
  border-width: 1px;
  border-radius: 10px;
  font: unset;
  font-size: 16px;
  height: 45px;
}

.inputContainer select {
  width: 100%;
}

.inputContainer textarea {
  width: 95%;
  background-color: var(--background);
  min-height: 80px;
  resize: none;
  border-style: solid;
  border-color: black;
  color: black;
  border-width: 1px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 10px;
  font: unset;
  font-weight: 500;
  font-size: 16px;
}

.inputContainer span {
  width: 98%;
  text-align: right;
  color: red;
  font-size: 14px;
  margin-top: 4px
}

.btnsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin-top: 10px;
}

.btn {
  height: 50px;
  width: 45%;
  background: none;
  border-style: solid;
  border-radius: 10px;
  border-color: black;
  font-size: 15px;
  font-weight: bold;
  border-width: 2px;
  cursor: pointer;
}

.back {
  color: black;
}

.next {
  background-color: black;
  color: white;
}

.btnsP3Container {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin-top: 10px;
}

.btnP3 {
  margin-bottom: 10px;
  outline: none;
  border-style: solid;
  border-radius: 10px;
  background: none;
  height: 50px;
  font: inherit;
  border-width: 2px;
  font-size: 20px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.accept {
  border-color: black;
  background-color: black;
  color: white;
}

.reject {
  color: white;
  background-color: #bc0000;
  border-color: #bc0000;
}

.backP3 {
  border-color: black;
  color: black;
}

.btnP3:active,
.btn:active {
  opacity: 0.7;
}

.statusContainer {
  display: flex;
  align-items: center;
  width: 90%;
}
.statusContainer img {
  margin-right: 10px;
  pointer-events: none;
}
.statusContainer p {
  font-weight: bold;
  font-size: 20px;
}
.acceptedStatus {
  color: #438970;
}
.declinedStatus {
  color: #bc0000;
}
.verify {
  background-color: #25aeed;
  border-color: #25aeed;
  color: white;
}
.unverify {
  background-color: #3a3a3a;
  margin-top: 40px;
}
.freeMonths {
  background-color: #437689;
  color: white;
  border-color: #437689;
}
.buttonImg {
  margin-right: 5px;
  pointer-events: none;
}

.tCContainer {
  width: 90%;
  margin-top: 15px;
  margin-bottom: 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.checkbox {
  height: 20px;
  aspect-ratio: 1;
  border-radius: 0;
  margin-right: 10px;
  cursor: pointer;
}

.terms {
  font-size: 17px;
  font-weight: 500;
  margin: 0;
}
.terms button {
  font: inherit;
  font-size: 17px;
  color: black;
  font-weight: bold;
  background: none;
  border: none;
  margin: none;
  padding: 0;
}
.terms button:active {
  opacity: 0.7;
  cursor: pointer;
  user-select: none;
}

.textAreaLength {
  margin: 0;
  margin-top: -5px;
  width: 90%;
  text-align: right;
  color: black;
  font-size: 15px;
}