.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
  padding-bottom: 10px;
}

.header {
  height: 80px;
  padding: 0 20px;
  box-sizing: border-box;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 800px;
  width: 90%;
  margin-top: 25px;
}

.heading {
  font-weight: bold;
  font-size: 16px;
  margin: 0;
  margin-bottom: 5px;
  padding: 0;
  width: 100%;
}

.bio {
  font-size: 16px;
  font-weight: 500;
  /* word-wrap: break-word;
  word-break: break-all; */
  margin: 0;
  width: 100%;
}

.readMoreBtn {
  border: none;
  outline: none;
  background: none;
  font-size: 16px;
  font-weight: bold;
  margin: 0;
  padding: 0;
  color: black;
  width: 100%;
  text-align: right;
}
.readMoreBtn:active {
  opacity: 0.7;
}

.bio a {
  color: var(--blue);
}

.buttonImg {
  margin-right: 5px;
  pointer-events: none;
}
.button {
  padding: 0;
  border: none;
  outline: none;
  width: 90%;
  height: 50px;
  border-radius: 10px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}
.button:active {
  opacity: 0.7;
}

@media (min-width: 1024px) {
  .container {
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
  }
  .content {
    margin: 0 100px;
    padding-right: 50px;
  }
}
