.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--background);
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 700px;
  position: relative;
}

.title {
  text-align: center;
  color: var(--blue);
  font-size: 22px;
  margin: 50px;
}

.resultsN {
  opacity: 0.4;
  font-size: 15px;
  font-weight: 500;
}

.buttonContainer {
  display: flex;
  width: 100%;
  margin-bottom: 5px;
  padding: 0 5%;
  padding-bottom: 2px;
  overflow-x: scroll;
  box-sizing: border-box;
  scrollbar-width: none; 
}

.buttonContainer::-webkit-scrollbar {
  display: none;
}


.filterButton {
  margin-right: 10px;
  border: 1px solid #000;
  border-radius: 20px;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  width: 30px;
}

.toggleButton {
  margin-right: 10px;
  padding: 5px 25px;
  border: 1px solid #000;
  border-radius: 20px;
  background-color: transparent;
  cursor: pointer;
  font-weight: bold;
  color: black;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
}


.activeButton {
  background-color: rgba(0, 0, 0, 0.1);
}

.selectContainer {
  position: absolute;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #ccc;
  opacity: 1;
  width: 90%;
  left: 5%;
  top: 105px;
  border-radius: 10px;
}

.select {
  list-style: none;
  padding: 2px 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  max-height: 250px;
  overflow-y: scroll;
  z-index: 5;
  width: 97%;
}

.categoryInput {
  outline: none;
  border: none;
  margin: 5px;
  min-height: 40px;
  background: none;
  font-size: 16px;
  border: 1px solid black;
  border-radius: 5px;
  padding: 5px;
  box-sizing: border-box;
  width: 97%;
}

.select li {
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin: 2px 2px;
  border-radius: 5px;
}

.select li:hover {
  background-color: #f0f0f0;
}

.select .selected {
  background-color: black;
  color: #fff;
}