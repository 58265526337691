.card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    border-radius: 15px;
    width: 90%;
    max-width: 700px;
    background-color: white;
    cursor: pointer;
    transition: transform 0.2s;
  }
  
  @media (min-width: 1024px) {
    .card:hover {
      transform: translateY(-5px);
    }
  }
  
  .image {
    width: 100%;
    aspect-ratio: 16/9;
    border-radius: 15px;
    object-fit: cover;
  }
  
  .categoriesContainer {
    display: flex;
    margin-left: 10px;
    margin-top: 10px;
  }
  
  .category {
    background-color: #e6e6e6;
    color: #333;
    padding: 5px;
    border-radius: 8px;
    margin-right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 500;
}
  
  .title {
    font-size: 18px;
    font-weight: 500;
    margin: 0 10px;
    margin-top: 10px
  }
  
  .date {
    color: #716D6D;
    margin: 0 10px;
    font-size: 14px
  }
  
  .content {
    font-size: 16px;
    margin-top: 10px;
    margin: 10px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
  }
  
  @media (min-width: 1024px) {
    .title {
      font-size: 18px;
    }
  }