.card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
  padding: 0;
  /* padding-right: 2px; */
  cursor: default;
  text-align: left;
}

.clickable {
  cursor: pointer;
}
.clickable:active {
  opacity: 0.7;
}

.content {
  display: flex;
  align-items: center;
}

.iconContainer {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  position: relative;
}

.defaultBackground {
  background-color: #d3dde1;
}

.alertBackground {
  background-color: rgba(224, 42, 42, 0.35);
}

.textContainer {
  margin-left: 15px;
  flex-grow: 1
}

.title {
  font-weight: 600;
  font-size: 16px;
  /* white-space: nowrap; */
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0;
  margin: 0;
  /* width: 80%; */
}

.date {
  color: #666;
  margin: 0;
  width: max-content;
}

.redDot {
  position: absolute;
  top: -3px;
  right: -3px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: red;
  border: 1px solid white;
}
