.container {
  display: flex;
  height: calc(100vh - 80px);
  flex-direction: column;
  align-items: center;
}

.content {
  display: flex;
  align-items: flex-start;
  width: 100%;
  flex: 1;
  gap: 30px;
  padding: 0 20px;
  box-sizing: border-box;
}
@media screen and (min-width: 1024px) {
  .content {
    width: 80%;
  }
}

.img {
  margin-top: 10px;
  margin-bottom: 50px;
  width: 120px;
  pointer-events: none;
}

.swiperContainer {
  width: 100%;
  height: 100%;
}

.swiperSlide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.navigationContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 45%;
  left: 0;
  width: 100%;
  z-index: 10;
  padding: 10px;
  box-sizing: border-box;
}

.navigationContainer button {
  background-color: black;
  border: none;
  outline: none;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navigationContainer button:disabled {
  background-color: #aaa;
}