.container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    min-height: calc(100vh - 100px);
    width: 100vw;
  }
  
  .content {
    width: 100%;
    max-width: 700px;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .msg {
    width: 90%;
  }

  .postBtn {
    position: fixed;
    background-color: #3E6471;
    border-radius: 50%;
    border: none;
    outline: none;
    right: 15px;
    bottom: 85px;
    justify-content: "center";
    align-items: "center";
    height: 50px;
    width: 50px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .postBtn:active {
    opacity: 0.7;
  }
  
  @media (min-width: 1024px) {
    .container {
      width: calc(100vw - 70px);
    }
    .postBtn {
      right: calc(50% - 500px);
    }
  }