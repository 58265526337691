.container {
  display: flex;
  width: 100%;
  padding: 10px 0;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.content {
  display: flex;
  align-items: center;
}

.img {
  width: 45px;
  height: 45px;
  margin-left: 20px;
  border-radius: 50%;
  padding: 1px;
  border-style: solid;
  border-width: 1.5px;
  border-color: var(--blue);
  object-fit: cover;
  pointer-events: none;
}

.txtContainer {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.name {
  margin: 0;
  margin-bottom: 2px;
  font-size: 18px;
  font-weight: 500;
}
.industry {
  margin: 0;
  color: var(--grey);
  font-size: 15px;
  font-weight: 500;
}

.checkmark {
  margin-right: 20px;
}
