.container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  margin: 10px;
  width: 90%;
}
.label {
  font-size: 15px;
}
.input {
  height: 50px;
  width: 100%;
  border-style: solid;
  border-width: 1px;
  border-radius: 10px;
  outline: none;
  background: none;
  border-color: black;
  color: black;
  padding: 0;
  margin: 0;
  font: inherit;
}
.textarea {
  height: 120px;
  width: 100%;
  border-style: solid;
  border-width: 1px;
  border-radius: 10px;
  outline: none;
  background: none;
  border-color: black;
  color: black;
  padding: 10px 0;
  margin: 0;
  font: inherit;
  resize: vertical;
}
