.card {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    width: 90%;
    border: 1px solid #999;
    border-radius: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  .card:active {
    opacity: 0.7;
  }
  
  .card:hover {
    background-color: #f5f5f5;
  }
  
  .cardContent {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .iconContainer {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
    background-color: #eee;
  }
  
  .title {
    font-size: 18px;
    font-weight: 500;
  }
  