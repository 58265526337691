.container {
  width: 90%;
  height: 45px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  position: relative;
}

.fieldContainer {
  border-style: solid;
  border-width: 1.5px;
  border-radius: 10px;
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
}

.input {
  height: 100%;
  width: 100%;
  outline: none;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  font: inherit;
  font-weight: 500;
  padding-left: 10px;
  padding-right: 10px;
}

.button {
  height: 100%;
  padding: 0 20px;
  border: none;
  outline: none;
  color: white;
  background-color: black;
  border-radius: 10px;
  font-weight: 500;
  font-size: 16px;
  margin-left: 10px;
}

.selectContainer {
  position: absolute;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #ccc;
  opacity: 1;
  width: 100%;
  right: 0;
  top: 50px;
  border-radius: 10px;
  z-index: 100;
  /* max-width: 500px; */
}

.select {
  list-style: none;
  padding: 2px 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  max-height: 250px;
  overflow-y: scroll;
  width: 97%;
}

.categoryInput {
  outline: none;
  border: none;
  margin: 5px;
  min-height: 40px;
  background: none;
  font-size: 16px;
  border: 1px solid black;
  border-radius: 5px;
  padding: 5px;
  box-sizing: border-box;
  width: 97%;
}

.select li {
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin: 2px 2px;
  border-radius: 5px;
}

.select li:hover {
  background-color: #f0f0f0;
}

.select .selected {
  background-color: black;
  color: #fff;
}