.container {
  display: flex;
  width: 100vw;
  justify-content: center;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #F6F7F7;
  width: 100%;
  max-width: 700px;
}

.profilePictureContainer {
  border: 2px solid #437689;
  border-radius: 80px;
  width: 130px;
  height: 130px;
  padding: 3px;
  margin-bottom: 20px;
  background-color: white;
}

.profilePicture {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

@media (min-width: 1024px) {
  .container {
    width: calc(100vw - 70px);
  }
}