.container {
  display: flex;
  width: 100vw;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 80px);
}

.content {
  width: 100%;
  max-width: 700px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media screen and (min-width: 1024px) {
  .centered {
    margin-left: -70px
  }
}

.error {
  color: red;
  font-size: 14px;
  margin-bottom: 5px;
  margin-top: -5px;
  width: 90%;
}

.cube {
  margin: 10px;
  pointer-events: none;
  width: 120px;
}

.invalidTitle {
  color: black;
  font-size: 26px;
  padding: 0;
  margin-bottom: 5px
}

.msg {
  margin: 0;
  margin-bottom: 20px;
  margin-top: -15px;
  text-align: center;
  width: 90%;
}

.forgetBtn {
  width: 90%;
  color: black;
  background: none;
  border: none;
  border-radius: 10px;
  font-size: 14px;
  font-weight: bold;
  text-align: right;
  margin-bottom: 30px;
}

.loginBtn {
  width: 90%;
  background-color: black;
  color: white;
  height: 55px;
  border: none;
  border-radius: 10px;
  font-size: 15px;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 100px;
}

.forgetBtn:active,
.loginBtn:active {
  opacity: 0.7;
}

.p {
  margin: 0;
  padding: 0
}
.p span {
  font-weight: bold; 
  cursor: pointer;
}
.p span:active {
  opacity: 0.7;
}

.link {
  background-color: black;
  outline: none;
  border: none;
  color: white;
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 100px;
  padding: 10px 30px;
  font-size: 15px;
  border-radius: 5px;
}

@media (min-width: 1024px) {
  .container {
    width: calc(100vw - 70px);
  }
}