.container {
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 70px);
}

.content {
  width: 100%;
  max-width: 700px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media screen and (min-width: 1024px) {
  .container {
    height: 100vh;
  }
  .content {
    margin-left: -70px;
  }
}

.cube {
  /* margin-bottom: 40px; */
  pointer-events: none;
  width: 120px;
}

.title {
  color: black;
  font-size: 22px;
  margin-bottom: 0;
}

.msg {
  margin: 0;
  margin-bottom: 20px;
  text-align: center;
  width: 90%;
}

.forgetBtn {
  width: 90%;
  color: black;
  background: none;
  border: none;
  border-radius: 10px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 30px;
  cursor: pointer;
}

.loginBtn {
  width: 90%;
  background-color: black;
  color: white;
  height: 55px;
  border: none;
  border-radius: 10px;
  font-size: 15px;
  font-weight: bold;
  margin-top: 10px;
  cursor: pointer;
}

.or {
  font-size: 18px;
  color: #9F9E9D;
  font-weight: 500;
  margin: 30px
}

.forgetBtn:active,
.loginBtn:active {
  opacity: 0.7;
}

.register {
  font-size: 14px;
  font-weight: 500;
  /* margin-top: 50px; */
  color: grey;
}
.register button {
  font: inherit;
  font-size: 14px;
  color: black;
  font-weight: bold;
  background: none;
  border: none;
  margin: none;
  padding: 0;
  cursor: pointer;
}
.register button:active {
  opacity: 0.7;
  cursor: pointer;
  user-select: none;
}

@media (min-width: 1024px) {
  .container {
    width: calc(100vw - 70px);
  }
}