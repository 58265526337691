.container {
  display: flex;
  flex: 1;
  justify-content: center;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f6f7f7;
  width: 100%;
  max-width: 700px;
}

.heading {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 700;
  text-align: left;
  width: 90%;
  max-width: 700px;
}