.container {
  display: flex;
  justify-content: center;
  width: 100vw;
  position: relative;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 700px;
}

.profilePicture {
  border-style: solid;
  border-width: 2px;
  border-radius: 50%;
  border-color: var(--blue);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 140px;
  width: 140px;
  padding: 3px;
  margin: 10px;
  position: relative;
}
.profilePicture:active {
  opacity: 0.7;
}

.userInfo {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5px;
}

.nameInput {
  border-color: black;
  border-style: solid;
  border-width: 1px;
  border-radius: 10px;
  outline: none;
  background: none;
  padding: 0;
  margin: 0;
  font: inherit;
  height: 35px;
  width: 50%;
  text-align: center;
  font-weight: bold;
  font-size: 20px;
}

.bioTextarea {
  border-color: black;
  border-style: solid;
  border-width: 1px;
  border-radius: 10px;
  outline: none;
  background: none;
  padding: 0;
  margin: 0;
  font: inherit;
  width: 81%;
  min-height: 50px;
  height: fit-content;
  text-align: left;
  font-weight: 500;
  font-size: 14px;
  padding: 2%;
  resize: vertical;
}
.taCount {
  margin: 0;
  color: black;
  font-size: 14px;
  font-weight: 500;
  text-align: right;
  width: 90%;
}
.errorSpan {
  color: red;
  font-size: 14px;
  margin-bottom: 5px;
}

.email {
  font-size: 16px;
}

.heading {
  font-weight: bold;
  font-size: 16px;
  margin: 0;
  margin-top: 25px;
  padding: 0;
  width: 90%;
}

.save {
  margin: 20px 0;
  padding: 0;
  border: none;
  background-color: black;
  outline: none;
  width: 90%;
  height: 50px;
  border-radius: 10px;
  color: white;
  font-weight: bold;
  font-size: 15px;
  cursor: pointer;
}

.save:active {
  opacity: 0.7;
}

@media (min-width: 1024px) {
  .container {
    width: calc(100vw - 70px);
  }
}


.linksBtn {
  width: 90%;
  background-color: white;
  padding: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 14px;
  margin: 5px 0;
  box-shadow: 0px 2px 4px rgba(170, 170, 170, 0.1);
  font-size: 18px;
  cursor: pointer;
}
.linksBtn:active {
  opacity: 0.7;
}

.iconContainer {
  margin-right: 10px;
  background-color: rgba(52, 122, 143, 0.38);
  padding: 7px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon {
  margin-left: 5px;
  cursor: pointer;
}
