.listItem {
    width: 90%;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    cursor: pointer;
  }
  .listItem:active {
    opacity: 0.7;
  }
  
  .listIconContainer {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
  }
  
  .listItemDate {
    font-size: 14px;
    margin: 0
  }
  
  .listItemDescription {
    width: 95%;
    font-size: 17px;
    font-weight: 500;
    margin: 0
  }
  
  .listItemContent {
    flex: 1;
  }
  
  .listItemPrice {
    font-size: 18px;
    font-weight: 500;
  }
  