.loaderContainer {
  background-color: var(--background);
  position: fixed;
  z-index: 1;
  height: calc(100vh - 70px);
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  box-sizing: border-box;
  padding-top: 70px;
}
@media screen and (min-width: 1024px) {
  .loaderContainer {
    height: 100vh;
    width: calc(100vw - 70px);
    left: unset;
    right: 0;
    padding-top: 0;
    padding-right: 70px;
  }
}

.appContainer {
  /* min-height: calc(100vh - 200px); */
}