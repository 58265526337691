.container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-color: var(--blue);
    width: 100%;
    margin-top: 20px;
}

.logo {
    width: 30%;
    max-width: 100px;
    margin-top: 20px;
    margin-bottom: 20px;
}

@media screen and (min-width: 768px) {
}