.container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  background-color: #f6f7f7;
  position: relative;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 700px;
  position: relative;
}

.whatsappButton {
  background: none;
  border: none;
  cursor: pointer;
}

.headingContainer {
  margin-top: 20px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
}

.heading {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 700;
  text-align: left;
  max-width: 700px;
}

.viewAllBtn {
  color: #3E6471;
  font-size: 14px;
  font-weight: 600;
  background: none;
  border: none;
  outline: none
}

.blogList {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.postBtn {
  position: fixed;
  background-color: #3E6471;
  border-radius: 50%;
  border: none;
  outline: none;
  right: 15px;
  bottom: 85px;
  justify-content: "center";
  align-items: "center";
  height: 50px;
  width: 50px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}
.postBtn:active {
  opacity: 0.7;
}

.myBlogsBtn {
  background-color: #eee;
  bottom: 145px;
  right: 20px;
  width: 40px;
  height: 40px;

}

@media (min-width: 1024px) {
  .postBtn {
    right: calc(50% - 500px);
  }
  .myBlogsBtn {
    right: calc(50% - 495px);
  }
}