.card {
    width: 90%;
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: 500px;
  }
  
  .img {
    width: 100%;
    flex: 1;
    aspect-ratio: 1;
    border-radius: 30px;
    object-fit: cover;
    background-color: #aaa;
  }
  
  .cardMainInfo {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    position: absolute;
    /* top: 210px; */
    bottom: 40px;
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
  }
  
  .nameContainer {
    width: 45%;
  }
  
  .name {
    font-weight: bold;
    font-size: 20px;
    color: white;
    text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.75);
  }
  
  .industry {
    font-size: 18px;
    margin-top: 10px;
    color: white;
    text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.75);
  }

  .saveContact {
    position: absolute;
    top: 20px;
    right: 20px
  }

  .userActions {
    display: flex;
  }
  
  .userAction {
    background-color: rgba(93, 89, 89, 0.7);
    width: 40px;
    height: 40px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
    cursor: pointer;
  }
  .userAction:active {
    opacity: 0.7;
  }
  
  .cardSecondaryInfo {
    width: 75%;
    display: flex;
    justify-content: space-between;
    background-color: white;
    padding: 10px 20px;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    margin-top: -2px;
    z-index: -1;
  }
  
  .secondaryInfoItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 45%;
  }
  
  .secondaryInfoTitle {
    color: #A5A5A5;
    font-size: 14px;
    font-weight: 300;
  }
  .cardImageContainer {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
  }
  
  .secondaryInfoSubtitle {
    font-size: 16px;
    font-weight: 500;
    margin-top: 2px;
    text-align: center;
    /* white-space: nowrap; */
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 90%;
  }
  
  
  .verticalSep {
    min-height: 40px;
    width: 0.5px;
    background-color: #ccc;
    z-index: 10;
  }
  
  @media (min-width: 1024px) {
    .card {
      margin-left: 100px;
    }
  }