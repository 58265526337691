.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f6f7f7;
}

.pageContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f6f7f7;
  width: 90%;
  max-width: 700px;
}

.imagePicker {
  width: 100%;
  aspect-ratio: 16 / 9;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e6e6e6;
  overflow: hidden;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.input {
  width: 100%;
  font-size: 18px;
  margin-top: 15px;
  font-weight: bold;
  outline: none;
  border: none;
  border-bottom: 1px solid #ccc;
  background: none;
  box-sizing: border-box;
}

.content {
  width: 100%;
  min-height: 200px;
  outline: none;
  border: none;
  background: none;
  padding: 5px 0;
  margin-top: 10px;
  resize: none;
  text-align: start;
  font-size: 16px;
  box-sizing: border-box;
  font-family: unset;
}

.characterCount {
  width: 100%;
  font-weight: 500;
  text-align: right;
  color: green;
  margin-bottom: 10px;
}
.warning {
    color: red;
}

.topicsContainer {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 10px;
  /* margin-bottom: 10px; */
}

.topic {
  padding: 5px;
  border-radius: 8px;
  background-color: #e6e6e6;
  border: none;
  margin-right: 8px;
  margin-bottom: 8px;
  padding: 5px 15px;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
  color: black;
}

.checkContainer {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.label {
  font-size: 16px;
  margin-left: 5px;
}

.publishButton {
  width: 100%;
  display: flex;
  align-items: center;
  margin: 20px 0;
  cursor: pointer;
}
.publishButton span {
  margin-left: 10px;
}

.submitButton {
  width: 100%;
  background-color: black;
  border: none;
  color: white;
  height: 50px;
  border-radius: 10px;
  font-size: 16px;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 20px;
  cursor: pointer;
}