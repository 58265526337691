.cardGradient {
  background-color: black;
  width: 100%;
  max-width: 600px;
  min-height: calc(100vh - 300px);
  padding: 5px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  display: flex;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
}

.cardHeader {
  display: flex;
  align-items: center;
  margin: 8px 0 15px;
}

.name {
  text-align: center;
  color: #fff;
  font-weight: 600;
  font-size: 18px;
  margin: 0;
  margin-left: 5px;
}

.card {
  background-color: white;
  width: 100%;
  flex: 1;
  padding: 15px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
}

.price {
  text-align: center;
  color: #237695;
  font-weight: bold;
  font-size: 30px;
  margin: 0;
}

.length {
  text-align: center;
  color: #666;
  font-weight: 500;
  font-size: 16px;
  margin: 0;
}

.sep {
  width: 100%;
  height: 1px;
  background-color: #ccc;
  margin: 20px 0;
}

.bullet {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.bulletTxt {
  max-width: 90%;
  padding-left: 10px;
  font-size: 16px;
}

.btn {
  border-radius: 10px;
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  border: 1.5px solid black;
  background-color: white;
  cursor: pointer;
  font-weight: 500;
  font-size: 15px;
  color: black;
}
