.container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 700px;
}
.contentPadding {
  margin-bottom: -50px;
}

@media screen and (min-width: 1024px) {
  .contentPadding {
    margin-left: -70px;
  }
}

.cube {
  margin: 10px;
  margin-top: 50px;
  width: 128px;
  pointer-events: none;
  user-select: none;
}

.title {
  text-align: center;
  color: var(--blue);
  font-size: 20px;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 40px;
}

.heading {
  font-size: 17px;
  font-weight: bold;
  color: var(--blue);
  width: 90%;
  margin: 0;
  margin-top: 25px;
}

.text {
  font-size: 16px;
  width: 90%;
  margin: 0;
  margin-top: 5px;
}
.text li {
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 5px;
}

@media (min-width: 990px) {
  .lastTest {
    margin-bottom: 140px;
  }
  .title {
    font-size: 26px;
  }
}
